.profile {
  .post-content {
    @include h1 {
      margin-bottom: 50px;
      text-align: left;
      @include tab {
        margin-bottom: 80px;
      }
    }
    h3 {
      font-size: 1em;
      margin: 20px 0 2px;
    }
  }
}
