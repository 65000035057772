.featured-media {
  img {
    max-width: 500px;
    margin: auto;
  }

  &-txt {
    .featured-media-txt_bk {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease-in-out;

      p {
        font-size: 1.5em;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  &-profile {
    .featured-media-profile_bk {
      background: $main-brown;
      height: 100px;

      p {
        color: $white;
      }

      &:hover {
        background: $white;
        border: solid 3px $main-brown;

        p {
          color: $main-brown;
        }
      }
    }
  }

  &-secret {
    .featured-media-secret_bk {
      background: $white;
      height: 200px;
      border: solid 3px $main-brown;

      p {
        color: $main-brown;
      }

      &:hover {
        background: $main-brown;
        p {
          color: $white;
        }
      }
    }
  }
}
