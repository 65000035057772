footer {
  position: fixed;
  bottom: 0;
  padding: 15px;
  width: 100%;
  .scroll-top {
    font-size: 1.2em;
    font-weight: bold;
    letter-spacing: 2.6px;
    padding: 15px;
    &::before {
      font-family: "Font Awesome 5 Free";
      content: "\f0d8";
      font-weight: 900;
    }
  }
}
