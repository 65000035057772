.secret-works {
  @include h1 {
    margin-top: 60px;
    @include tab {
      margin-top: 40px;
    }
  }
  &-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  &-container {
    width: 33.33333%;
    padding: 10px;
    @include tab {
      width: 100%;
      padding: 0;
      margin-top: 20px;
    }
  }
  .secret-work {
    width: 100%;
    padding: 16px;
    background: $white;
    border: 3px solid $main-brown;
    &-featured-media {
      display: block;
      overflow: hidden;
      position: relative;
      img {
        transition: all 0.4s ease-in-out;
      }
      &:hover {
        img {
          transform: scale(1.1);
          -moz-transform: scale(1.1);
          -webkit-transform: scale(1.1);
        }
      }
    }
    &-title {
      color: peru;
      @include targetIcon;
    }
  }
  .panel_area {
    // display: none;
    .tab_panel {
      background: $white;
      margin-top: 40px;
      &_inner {
        // display: none;
        width: 620px;
        max-width: 85%;
        padding: 7.5% 0;
        margin: 0 auto;
        &.active {
          display: block;
        }
        img {
          &.sp_img {
            max-width: 375px;
            width: 100%;
          }
        }
      }
    }
  }
}
