.post {
  &-password-form {
    width: 620px;
    max-width: 85%;
    padding: 7.5% 0;
    margin: 0 auto;
    p {
      margin: 15px 0;
    }
    input[type="password"] {
      border: solid 1px $main-brown;
      padding: 11px;
      font-size: 1em;
    }
    input[type="submit"] {
      background: $main-brown;
      border: none;
      color: white;
      font-size: 1em;
      font-weight: bold;
      padding: 10px 20px;
      margin: 0 5px;
    }
  }
  &-content {
    padding-bottom: 100px;
    h1 {
      font-size: 1em;
      @include targetIcon;
    }
    h2 {
      font-size: 1.4em;
    }
  }

  &.single {
    padding-top: 60px;
  }

  &-inner {
    padding: 0;
  }
}
